import { mapMutations, mapState } from 'vuex';
import {
  removeStudentGroup,
  removeEmployee,
  reactivateEmployee,
  fetchEmployee,
} from '../../helpers/employeeOptions';
import { getItem } from '@/api/api-methods';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnTable from '../../../shared/components/KnTable.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import { getFullName } from '../../../shared/helpers/dataUtils';
import {
  canAdd,
  canChange,
  canDelete,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
import { fetchTypesEmployee } from '../../../configuration/helpers/KnEmployeeTypesOptions';

export default {
  components: { KnLocalAlert, KnTable },
  mixins: [paginationMixin],
  data() {
    return {
      employees: [],
      headers: [
        { text: '', value: 'foto', sortable: false },
        {
          text: 'Nombre',
          value: 'nombre',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Tipo empleado',
          value: 'tipo_empleado.dato',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Tipo usuario',
          value: 'userType',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Grupos',
          value: 'grupo',
          class: 'purple--text',
          sortable: false,
        },
        {
          text: 'Materias',
          value: 'materias',
          class: 'purple--text',
          sortable: false,
        },
        { text: 'ID', value: 'id', class: 'purple--text', sortable: false },
        { text: '', value: 'acciones', class: 'purple--text', sortable: false },
      ],
      items: [],
      inactiveItems: [],
      //search: null,
      showInactive: false,
      loading: false,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'info',
      alertText: insufficientPermissionsMessage(),
      alertColor: 'warning',
      /*********************** */
      routerName: null,
      teacherType: null,
    };
  },
  async created() {
    this.setIsLogin(false);
    const { ok, data, message } = await fetchTypesEmployee({
      dato: 'docente',
      institutionId: this.institutionId,
      systemStatus: true,
    });
    if (ok && data.length) {
      this.teacherType = data[0];
    } else {
      console.log('No se pudo obtener el tipo de empleado docente', message);
    }
    await this.getPaginatedEmployee(1);
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search', 'clearSearch']),
    tableTitle() {
      return this.showInactive ? 'Profesores inactivos' : 'Profesores';
    },
    buttomActionText() {
      return this.showInactive
        ? 'Ver profesores activos'
        : 'Ver profesores inactivos';
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedEmployee(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedEmployee(1);
      },
    },
    search: {
      async handler() {
        if (
          this.search !== undefined &&
          this.search !== null &&
          this.search !== ''
        ) {
          await this.getSearchEmployee();
        } else {
          await this.getPaginatedEmployee(1);
        }
      },
    },
    clearSearch: {
      async handler() {
        this.search = '';
      },
    },
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    canAdd: canAdd,
    async getPaginatedEmployee(page = 1) {
      this.items = [];
      this.setPaginationPage(page);
      if (!this.loading) {
        this.loading = true;
        const { ok, data, message, count } = await fetchEmployee({
          institutionId: this.institutionId,
          typeEmployee: this.teacherType ? this.teacherType.id : null,
          systemStatus: !this.showInactive,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        });
        if (ok) {
          this.setPaginationCount(count);
          const employeeArray = data;
          for (const employee of employeeArray) {
            const fullName = getFullName(employee.datos_personales);
            const { results: groups } = await getItem(
              `/app-personas/filters/grupo?institucion_educativa=${this.institutionId}&profesores=${employee.id}`
            );
            let group = 'Sin asignar';
            let groupId = null;
            if (groups && groups.length) {
              group = groups[0].nombre_grupo;
              groupId = groups[0].id;
            }

            let userType = 'Sin asignar';
            const userTypeId = employee.usuario.tipo_usuario;
            if (userTypeId) {
              const userTypeRes = await getItem(
                `/app-land/tipo-usuario/${userTypeId}`
              );
              // console.log('userTypeRes', userTypeRes);

              if (userTypeRes.e) {
                console.log(
                  'No se pudo obtener el tipo usuario',
                  userTypeRes.e
                );
              } else {
                userType = userTypeRes.tipo_usuario;
              }
            }

            const item = {
              foto:
                employee.foto_perfil === null
                  ? require('../../../../assets/images/huellita.png')
                  : employee.foto_perfil.imagen,
              nombre: fullName,
              grupo: group,
              id: employee.id,
              groupId: groupId,
              userType: userType,
              ...employee,
            };
            this.items.push(item);
          }
        } else {
          console.log('No se pudieron obtener empleados', message);
        }
        this.loading = false;
      }
    },
    async getSearchEmployee() {
      this.items = [];
      this.loading = true;
      try {
        if (
          this.search !== undefined &&
          this.search !== null &&
          this.search !== ''
        ) {
          const searchFilter = `${this.search}`;
          const { ok, message, results, count } = await getItem(
            `/app-personas/helpers/buscar-empleado-por-nombre-completo/${searchFilter}/${!this
              .showInactive}/${this.institutionId}`
          );

          if (ok === false) return { message };

          const employeePromises = results.map(async (employee) => {
            const fullName = getFullName(employee.datos_personales);

            const { results: groups } = await getItem(
              `/app-personas/filters/grupo?institucion_educativa=${this.institutionId}&profesores=${employee.id}`
            );

            let group = 'Sin asignar';
            let groupId = null;
            if (groups && groups.length) {
              group = groups[0].nombre_grupo;
              groupId = groups[0].id;
            }
            const item = {
              foto:
                employee.foto_perfil === null
                  ? require('../../../../assets/images/huellita.png')
                  : employee.foto_perfil.imagen,
              nombre: fullName,
              grupo: group,
              id: employee.id,
              groupId: groupId,
              ...employee,
            };
            return item;
          });

          const employees = await Promise.all(employeePromises);
          this.setPaginationCount(count);
          this.items = employees;
        } else {
          await this.getPaginatedEmployee(1);
        }
      } catch (error) {
        console.error('Error al obtener empleados:', error);
      } finally {
        this.loading = false;
      }
    },
    async action2(supplierId) {
      if (this.showInactive) {
        await this.enableEmployee(supplierId);
      } else {
        await this.disableEmployee(supplierId);
      }
    },
    async disableEmployee(studentId) {
      try {
        if (canDelete('empleado')) {
          const student = this.items.find((s) => s.id === studentId);
          const groupId = student.groupId;
          if (groupId !== null) {
            const { ok } = await removeStudentGroup(studentId, groupId);
            if (ok) {
              // this.alertText = 'Empleado desasignado de grupo exitosamente';
            }
          }
          const { ok } = await removeEmployee(studentId);
          if (ok) {
            // this.alertText = 'Empleado desactivado exitosamente';
          }
          this.items = [];
          await this.getPaginatedEmployee(1);
        } else {
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al intentar desactivar alumno', error);
      }
    },

    async enableEmployee(studentId) {
      try {
        if (canChange('empleado')) {
          const { ok } = await reactivateEmployee(studentId);
          if (ok) this.alertText = 'Alumno activado exitosamente';
          this.items = [];
          await this.getPaginatedEmployee(1);
        } else {
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al intentar reactivar un alumno', error);
      }
    },
    async searchEmployee() {
      // console.log('Hola mundo');
    },
  },
};
